import { createSlice } from "@reduxjs/toolkit";

interface HeaderState {
    showHeader: boolean;
}

const initialState: HeaderState = {
    showHeader: false
};

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        reset: (_state) => initialState,
        setShowHeader: (state, action) => {
            state.showHeader = action.payload;
        }
    },
});

export const {reset, setShowHeader} = headerSlice.actions;
export default headerSlice.reducer;
